import { FC, useState, useContext, useEffect } from 'react'
import { Card, Button, Row, Col, Form, InputGroup } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { AiFillSave } from 'react-icons/ai'
import SoftBadge from 'components/common/SoftBadge'
import AppContext from 'context/Context'
import CustomLinkLeft from 'components/common/CustomLinkLeft'
import { useLoading } from 'context/LoadingBar/LoadingBar'
import Select from 'react-select'
import {
	IOption,
	IFormData,
	IDataSendToHandleBusinessPartner,
	IDataEditBusinessPartner
} from './interfaces'
import states from 'helpers/states'
import debounce from 'debounce-promise'
import AsyncSelect from 'react-select/async'
import { search } from 'services/vittamais-core/county/county'
import InputMask from 'react-input-mask'
import useSweetAlert from 'context/Alerts/SweetAlert'
import useAuth from 'hooks/useAuth'
import { useToast } from 'context/Toasts/Toastify'
import {
	create,
	update,
	loadDataEditform
} from 'services/vittamais-core/customer/customer'
import { AiOutlineSearch } from 'react-icons/ai'
import { search as countySeacrh } from 'services/vittamais-core/county/county'
import HandleCustomerModal from './components/HandleCustomerModal'
const FormCustomers: FC = function FormCustomers() {
	const { setLoading } = useLoading()
	const navigate = useNavigate()
	const { dataAuth } = useAuth()
	const { showAlert } = useSweetAlert()
	const { showToast } = useToast()
	const {
		config: { isDark, isRTL },
		setConfig
	} = useContext(AppContext)
	const customStyles = {
		control: (provided: any, state: any) => {
			const isDisabled = state.selectProps.isDisabled

			let color = isDark ? '#dae3f0 !important' : ''
			let backgroundColor = isDark ? '#0b1727' : ''
			let borderColor = isDark ? '#344050' : '#dae3f0'

			if (isDisabled && !isDark) {
				backgroundColor = '#edf2f9'
				color = '#dae3f0 !important'
				borderColor = '#dce5f1'
			}

			return {
				...provided,
				backgroundColor,
				color,
				borderColor
			}
		}
	}
	const { id } = useParams()
	const [stateOptions, setStateOptions] = useState<any[]>([])
	const [controlCustomerModal, setControlCustomerModal] = useState({
		show: false
	})
	const [controlLoading, setControlLoading]=useState(false)
	const [FormData, setFormData] = useState<IFormData>({
		bp_type: '1',
		name: '',
		cpf: '',
		cnpj: '',
		person_type: '1',
		phone: '',
		mobile_phone: '',
		email: '',
		address_cep: '',
		address_state: '',
		address_county_id: '',
		address_district: '',
		address_complement: '',
		address_number: '',
		address: '',
		status: '',
		created_at: '',
		pdv_id: dataAuth?.origin_scope?.pdv_origin?.id,
		fantasy_name: '',
		name_legal_representative: '',
		cpf_legal_representative: '',
		rg_org: '',
		rg_uf: '',
		rg_number: '',
		birth_date: '',
		pdv_disabled: false
	})
	const handleFieldChange = (e: any): void => {
		setFormData({
			...FormData,
			[e.target.name]: e.target.value
		})
	}

	const handleSubmit = async (e: any) => {
		e.preventDefault()
		try {
			setControlLoading(true)
			let state = ''
			let count_id = ''
			let rg_uf
			if (FormData.address_state) {
				state = FormData.address_state.value
			}
			if (FormData.rg_uf) {
				rg_uf = FormData.rg_uf.value
			}
			if (FormData.address_county_id) {
				count_id = FormData.address_county_id?.value
			}
			let dataSend: IDataSendToHandleBusinessPartner = {
				bd_type: parseInt(FormData?.bp_type?.toString()),
				name: FormData.name,
				cpf: FormData.cpf,
				cnpj: FormData.cnpj,
				person_type: parseInt(FormData?.person_type?.toString()),
				phone: FormData.phone,
				mobile_phone: FormData.mobile_phone,
				email: FormData.email,
				address_cep: FormData.address_cep,
				address_state: !state ? null : state,
				address_county_id: !count_id ? null : count_id,
				address_district: !FormData.address_district
					? null
					: FormData.address_district,
				address: !FormData.address ? null : FormData.address,
				address_complement: !FormData.address_complement
					? null
					: FormData.address_complement,
				pdv_id: parseInt(FormData?.pdv_id?.toString()),
				status: FormData.status ? parseInt(FormData?.status?.toString()) : 1,
				address_number: FormData?.address_number,
				fantasy_name: FormData.fantasy_name ? FormData.fantasy_name : null,
				name_legal_representative: FormData.name_legal_representative
					? FormData.name_legal_representative
					: null,
				cpf_legal_representative: FormData.cpf_legal_representative
					? FormData.cpf_legal_representative
					: null,
				rg_org: FormData.rg_org ? FormData.rg_org : null,
				rg_uf: rg_uf ? rg_uf : null,
				rg_number: FormData.rg_number ? FormData.rg_number : null,
				birth_date: FormData.birth_date ? FormData.birth_date : null
			}
			if (!dataSend.address_cep ||
				!dataSend.address ||
				(dataSend.address && dataSend.address.trim() == '') ||
				!dataSend.address_number ||
				(dataSend.address_number && dataSend.address_number.trim() == '') ||
				!dataSend.address_state ||
				!dataSend.address_county_id ||
				!dataSend.address_district ||
				(dataSend.address_district && dataSend.address_district.trim() == '')){
					setControlLoading(false)
					return (
						showAlert({
							title: 'Ops...!',
							text: `Preencha todos os campos relacionado a endereço.`,
							type: 'error'
						})
					)
				} 
			if(id && FormData.blacklist){
				dataSend.blacklist = FormData.blacklist == 0? null: FormData.blacklist
			}
			let gender:any = FormData.gender;
			if(gender != "F" && gender != "M") gender = null
			else gender = FormData.gender
			dataSend.gender = gender
			if (!id) {
				await create(dataSend)
				showToast('Cliente criado com sucesso!', 'success')
			} else {
				await update(parseInt(id), dataSend)
				showToast('Cliente atualizado com sucesso!', 'success')
			}
			setControlLoading(false)
			navigate('/clientes')
		} catch (e: any) {
			setControlLoading(false)
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					title: 'Ops...!',
					text: `${e?.response?.data?.error_message}`,
					type: 'error'
				})
				return
			}
			showAlert({
				title: 'Ops...!',
				text: 'Aconteceu algo inesperado, verifique os campos e tente novamente.',
				type: 'error'
			})
		}
	}

	const loadDataPage = () => {

		if (!id) {
			setControlCustomerModal({ show: true })
		}

		let formattedStates: IOption[] = states.map(item => ({
			label: item.estado,
			value: item.sigla
		}))

		setStateOptions(formattedStates)


		loadDataEditPage()
	}
	const loadDataEditPage = async () => {
		try {
			if (!id) return
			setLoading(true)
			let dataForm = { ...FormData }
			let dataEdit: IDataEditBusinessPartner = await loadDataEditform(parseInt(id))
			let foundStates = 0
			for (let i = 0; i < states?.length; i++) {
				if (states[i].sigla == dataEdit?.address_state) {
					dataForm.address_state = {
						label: states[i].estado,
						value: states[i].sigla
					}
					foundStates++
				}
				if (states[i].sigla == dataEdit?.rg_uf) {
					dataForm.rg_uf = {
						label: states[i].estado,
						value: states[i].sigla
					}
					foundStates++
				}
				if (foundStates == 2) {
					break
				}
			}
			if (dataEdit.county) {
				dataForm.address_county_id = {
					label: dataEdit.county.name,
					value: dataEdit.county.id
				}
			}
			if (dataEdit?.pdv_id && dataEdit?.pdv_id != '') {
				dataForm.pdv_disabled = true
			}
			dataForm.name = dataEdit.name
			dataForm.address = dataEdit.address
			dataForm.address_cep = dataEdit.address_cep
			dataForm.address_complement = dataEdit.address_complement
			dataForm.cpf = dataEdit.cpf
			dataForm.cnpj = dataEdit.cnpj
			dataForm.status = dataEdit.status
			dataForm.bp_type = dataEdit.bd_type
			dataForm.person_type = dataEdit.person_type
			dataForm.phone = dataEdit.phone
			dataForm.mobile_phone = dataEdit.mobile_phone
			dataForm.address_district = dataEdit.address_district
			dataForm.email = dataEdit.email
			dataForm.created_at = dataEdit.created_at
			dataForm.pdv_id = dataEdit.pdv_id ? dataEdit.pdv_id : ''
			dataForm.fantasy_name = dataEdit.fantasy_name ? dataEdit.fantasy_name : ''
			dataForm.name_legal_representative = dataEdit.name_legal_representative
				? dataEdit.name_legal_representative
				: ''
			dataForm.cpf_legal_representative = dataEdit.cpf_legal_representative
				? dataEdit.cpf_legal_representative
				: ''
			dataForm.rg_org = dataEdit.rg_org ? dataEdit.rg_org : ''
			dataForm.rg_number = dataEdit.rg_number ? dataEdit.rg_number : ''
			dataForm.birth_date = dataEdit.birth_date ? dataEdit.birth_date : ''
			dataForm.address_number = dataEdit.address_number
			dataForm.gender = dataEdit.gender? dataEdit.gender : ''
			if(dataEdit.blacklist) dataForm.blacklist = dataEdit.blacklist
			setFormData(dataForm)
		} catch (e) {
			console.log(e)
		}
		setLoading(false)
	}

	const handleAddressState = (e: any): void => {
		let address_county_id = FormData.address_county_id
		if (FormData.address_state) {
			if (e.value != FormData?.address_state?.value) {
				if (e.value !== FormData?.address_state?.value) {
					address_county_id = ''
				}
			}
		}

		setFormData({
			...FormData,
			address_state: e,
			address_county_id: address_county_id
		})
	}

	const handleRgUF = (e: any): void => {
		setFormData({
			...FormData,
			rg_uf: e
		})
	}
	const handleAddrCounty = (e: any): void => {
		setFormData({
			...FormData,
			address_county_id: e
		})
	}
	const loadCountyInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				if (!FormData.address_state) return resolve([])

				let response: any = await search(
					{ state: FormData?.address_state?.value, term: term },
					1,
					20
				)

				if (!response || response?.data?.length <= 0) return

				let counties_value: any = []
				response?.data?.forEach((element: any) => {
					counties_value.push({
						label: element.name,
						value: element.id
					})
				})

				return resolve(counties_value)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}

	const debounceOptionsCounty: any = debounce(loadCountyInputOptions, 600)

	const renderDate = (dateString: any) => {
		if (!dateString) return
		const date = new Date(dateString)
		const formattedDate = date.toLocaleDateString('pt-BR')
		const formattedTime = date.toLocaleTimeString('pt-BR')

		return `${formattedDate} ${formattedTime}`
	}

	const renderStatus = (status: string) => {
		if (status == '1') {
			return (
				<SoftBadge pill bg='success'>
					Ativo
				</SoftBadge>
			)
		} else {
			return (
				<SoftBadge pill bg='danger'>
					Inativo
				</SoftBadge>
			)
		}
	}
	const handleCep = async (dataForm?: any) => {
		try {
			let dataCep: any = '';
			if (dataForm?.address_cep) {
				dataCep = dataForm?.address_cep;
			}



			if (!dataCep || isNaN(dataCep) === true || dataCep?.toString()?.length < 8) {
				showAlert({
					title: 'Atenção!',
					text: 'Preencha os 8 algarismos do CEP!',
					type: 'info'
				})
				return
			}
			const url = 'https://viacep.com.br/ws/' + dataCep + '/json/'

			let data = await fetch(url)

			const endereco = await data.json()
			if (endereco?.erro) {
				showAlert({
					title: 'Ops...!',
					text: 'Não encontramos nenhuma informação no CEP fornecido!',
					type: 'error'
				})
				handleClearData(dataForm)
				return
			}
			handleFillDataCEP(endereco, dataForm)
		} catch (e) {
			console.log(e)
		}
	}
	const handleClearData = (dataForm: any) => {
		let newData = { ...dataForm }
		newData.address_district = ''
		newData.address = ''
		newData.address_county_id = ''
		newData.address_state = ''
		setFormData(newData)
	}
	const handleFillDataCEP = async (data: any, dataForm: any) => {
		try {
			let newData = { ...dataForm }
			newData.address_district = data.bairro
			newData.address = data.logradouro

			let response = await countySeacrh({ ibge_code: data.ibge })
			newData.address_county_id = {
				label: response.name,
				value: response.id
			}

			let address_state: any = ''
			for (let i = 0; i < states?.length; i++) {
				if (states[i].sigla == data.uf) {
					address_state = {
						label: states[i].estado,
						value: states[i].sigla
					}
				}
			}
			newData.address_state = address_state
			setFormData(newData)
		} catch (e) {
			console.log(e)
		}
	}
	useEffect(() => {
		loadDataPage()
	}, [id])

	return (
		<Row>
			<Col lg={12}>
				<Form onSubmit={handleSubmit}>
					<Card>
						<Card.Body>
							{!id ? (
								<>
									{' '}
									<Row className='d-flex justify-content-end'>
										<Col sm='6'>
											<h5>Adicionar Cliente</h5>
										</Col>
										<Col sm='6' align='end'></Col>
									</Row>
									<Row className='mt-4'>
										<Col sm='6'>
											<CustomLinkLeft to='/clientes' />
										</Col>
										<Col sm='6' align='end'>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}
											>
												<Button disabled={controlLoading} type='submit' variant='falcon-default'>
													<AiFillSave style={{ position: 'relative', top: '-1px' }} /> Salvar
												</Button>
											</div>
										</Col>
									</Row>
								</>
							) : (
								<div>
									<Row className='d-flex justify-content-end'>
										<Col sm='6'>
											<h5>Editar Cliente: #{id}</h5>
											<div>
												<h6 style={{ color: '#8d99a7' }}>
													{renderDate(FormData?.created_at)}
												</h6>
											</div>
										</Col>
										<Col sm='6' align='end'>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end', gap: '4px' }}
											>
												<h5 style={{ color: '#757e89' }}>Status: </h5>{' '}
												<span style={{ position: 'relative', top: '-2px' }}>
													{renderStatus(FormData?.status)}
												</span>
											</div>
										</Col>
									</Row>
									<Row className='d-flex justify-content-end mt-3'>
										<Col sm='6'>
											<CustomLinkLeft to='/clientes' />
										</Col>
										<Col sm='6' align='end'>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}
											>
												<Button disabled={controlLoading} type='submit' variant='falcon-default'>
													<AiFillSave style={{ position: 'relative', top: '-1px' }} /> Salvar
												</Button>
											</div>
										</Col>
									</Row>
								</div>
							)}
						</Card.Body>
					</Card>
					<Card className='mt-3'>
						<Card.Body>
							<Row>
								{/* <Col> */}
								{/* <div className='bg-light card-body mt-2'> */}
								<div className=''>
									<Row>
										<Col lg='2'>
											<Form.Group>
												<Form.Label>Natureza cliente</Form.Label>
												<Form.Select
													name='person_type'
													value={FormData.person_type}
													onChange={handleFieldChange}
												>
													<option value='1'>Pessoa Física</option>
													<option value='2'>Pessoa Jurídica</option>
												</Form.Select>
											</Form.Group>
										</Col>
										<Col lg='2'>
											<Form.Group>
												<Form.Label>Gênero(Sexo)</Form.Label>
												<Form.Select
													name='gender'
													value={FormData.gender}
													onChange={handleFieldChange}
												>
													<option value=''>Selecione</option>
													<option value='M'>Masculino</option>
													<option value='F'>Feminino</option>
												</Form.Select>
											</Form.Group>
										</Col>
										{id ? (
											<>
											<Col lg='2'>
												<Form.Group>
													<Form.Label>Status</Form.Label>
													<Form.Select
														onChange={handleFieldChange}
														name='status'
														value={FormData.status}
													>
														<option value='2'>Inativo</option>
														<option value='1'>Ativo</option>
													</Form.Select>
												</Form.Group>
											</Col>
											<Col lg='2'  className="ms-auto">
												<Form.Group>
													<Form.Label>Blacklist</Form.Label>
													<Form.Select
														onChange={handleFieldChange}
														name='blacklist'
														value={FormData.blacklist?FormData.blacklist:''}
													>
														<option value={0}>Não</option>
														<option value={1}>Sim</option>
													</Form.Select>
												</Form.Group>
											</Col>
											</>
										) : (
											''
										)}
									</Row>
									<Row className='mt-1'>
										<Col lg='6'>
											<Form.Group>
												{FormData?.person_type == '1' ? (
													<Form.Label>Nome</Form.Label>
												) : (
													<Form.Label>Razão Social</Form.Label>
												)}

												<Form.Control
													value={FormData.name}
													name='name'
													onChange={handleFieldChange}
													type='text'
												/>
											</Form.Group>
										</Col>
										{FormData?.person_type == '1' ? (
											<Col lg='6'>
												<Form.Group>
													<Form.Label>CPF</Form.Label>
													<InputMask
														name='cpf'
														mask='999.999.999-99'
														placeholder='Digite CPF'
														className='form-control'
														onChange={handleFieldChange}
														value={FormData.cpf}
													/>
												</Form.Group>
												{/* <Form.Group>
														<Form.Label>CPF</Form.Label>
														
														<Form.Control
															value={FormData.cpf}
															name='cpf'
															onChange={handleFieldChange}
															type='text'
														/>
													</Form.Group> */}
											</Col>
										) : (
											<Col lg='6'>
												<Form.Group>
													<Form.Label>CNPJ</Form.Label>
													<InputMask
														name='cnpj'
														mask='99.999.999/9999-99'
														placeholder='Digite CNPJ'
														className='form-control'
														onChange={handleFieldChange}
														value={FormData.cnpj}
													/>
												</Form.Group>
											</Col>
										)}
									</Row>
									{FormData?.person_type == '2' && (
										<Row className='mt-1'>
											<Col lg='4'>
												<Form.Group>
													<Form.Label>Nome Fantasia</Form.Label>
													<Form.Control
														value={FormData.fantasy_name}
														name='fantasy_name'
														onChange={handleFieldChange}
														type='text'
													/>
												</Form.Group>
											</Col>
											<Col lg='4'>
												<Form.Group>
													<Form.Label>Nome Representante Legal</Form.Label>
													<Form.Control
														value={FormData.name_legal_representative}
														name='name_legal_representative'
														onChange={handleFieldChange}
														type='text'
													/>
												</Form.Group>
											</Col>
											<Col lg='4'>
												<Form.Group>
													<Form.Label>CPF do Representante Legal</Form.Label>
													<InputMask
														name='cpf_legal_representative'
														mask='999.999.999-99'
														placeholder='Digite CPF'
														className='form-control'
														onChange={handleFieldChange}
														value={FormData.cpf_legal_representative}
													/>
												</Form.Group>
											</Col>
										</Row>
									)}
									{FormData.person_type == '1' ? (
										<Row className='mt-1'>
											<Col lg='3'>
												<Form.Group>
													<Form.Label>Data de Nascimento</Form.Label>
													<Form.Control
														value={FormData.birth_date}
														name='birth_date'
														onChange={handleFieldChange}
														type='date'
													/>
												</Form.Group>
											</Col>
											<Col lg='3'>
												<Form.Group>
													<Form.Label>RG</Form.Label>
													<Form.Control
														value={FormData.rg_number}
														name='rg_number'
														onChange={handleFieldChange}
														type='text'
													/>
												</Form.Group>
											</Col>
											<Col lg='3'>
												<Form.Group>
													<Form.Label>UF do RG</Form.Label>
													<Select
														styles={customStyles}
														name='rg_uf'
														onChange={handleRgUF}
														placeholder='Selecione...'
														options={stateOptions}
														value={FormData.rg_uf}
													/>
												</Form.Group>
											</Col>
											<Col lg='3'>
												<Form.Group>
													<Form.Label>Orgão Emissor</Form.Label>
													<Form.Control
														value={FormData.rg_org}
														name='rg_org'
														onChange={handleFieldChange}
														type='text'
													/>
												</Form.Group>
											</Col>
										</Row>
									) : (
										''
									)}
								</div>
								{/* </Col> */}
							</Row>
						</Card.Body>
					</Card>
					<Card className='mt-3'>
						<Card.Body>
							<Row>
								{/* <Col> */}
								{/* <div className='bg-light card-body mt-2'> */}
								<div className=''>
									<Row className='mt-1'>
										<Col lg={'6'}>
											<Form.Group>
												<Form.Label>E-mail</Form.Label>
												<Form.Control
													value={FormData.email}
													name='email'
													onChange={handleFieldChange}
													type='text'
												/>
											</Form.Group>
										</Col>
										<Col lg='3'>
											<Form.Group>
												<Form.Label>Telefone Celular</Form.Label>
												<InputMask
													mask='(99) 99999-9999'
													value={FormData.mobile_phone}
													name='mobile_phone'
													onChange={handleFieldChange}
													type='text'
													className='form-control'
												></InputMask>
											</Form.Group>
										</Col>
										<Col lg='3'>
											<Form.Group>
												<Form.Label>Telefone</Form.Label>
												<InputMask
													mask='(99) 9999-9999'
													value={FormData.phone}
													name='phone'
													className='form-control'
													onChange={handleFieldChange}
													type='text'
												></InputMask>
												
											</Form.Group>
										</Col>
									</Row>
									<Row className='mt-1'>
										<Col lg='3'>
											<Form.Group>
												<Form.Label>CEP</Form.Label>
												<InputGroup className='mb-3'>
													<Form.Control
														value={FormData.address_cep}
														name='address_cep'
														onChange={handleFieldChange}
														type='text'
														maxLength={8}
													/>
													<Button
														disabled={controlLoading}
														variant='falcon-default'
														onClick={() => {
															handleCep(FormData)
														}}
													>
														<AiOutlineSearch size={20} />
													</Button>
												</InputGroup>
											</Form.Group>
										</Col>
										<Col lg={'7'}>
											<Form.Group>
												<Form.Label>Endereço</Form.Label>
												<Form.Control
													value={FormData.address}
													name='address'
													onChange={handleFieldChange}
													type='text'
												/>
											</Form.Group>
										</Col>
										<Col lg={2}>
											<Form.Group>
												<Form.Label>Número endereço</Form.Label>
												<Form.Control
													value={FormData.address_number}
													name='address_number'
													onChange={handleFieldChange}
													type='text'
												/>
											</Form.Group>
										</Col>
									</Row>
									<Row className='mt-1'>
										<Col lg={3}>
											<Form.Group>
												<Form.Label>Complemento</Form.Label>
												<Form.Control
													value={FormData.address_complement}
													name='address_complement'
													onChange={handleFieldChange}
													type='text'
												/>
											</Form.Group>
										</Col>
										<Col lg='2'>
											<Form.Group>
												<Form.Label>Estado</Form.Label>
												<Select
													styles={customStyles}
													name='state'
													onChange={handleAddressState}
													placeholder='Selecione...'
													options={stateOptions}
													value={FormData.address_state}
												/>
											</Form.Group>
										</Col>
										{FormData.address_state ? (
											<Col lg='3'>
												<Form.Group>
													<Form.Label>Cidade</Form.Label>
													<AsyncSelect
														styles={customStyles}
														id='addr_county_id'
														name='addr_county_id'
														placeholder='Selecionar cidade'
														isClearable
														loadOptions={debounceOptionsCounty}
														value={FormData.address_county_id}
														onChange={handleAddrCounty}
														noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
													/>
												</Form.Group>
											</Col>
										) : (
											''
										)}

										<Col lg='4'>
											<Form.Group>
												<Form.Label>Bairro</Form.Label>
												<Form.Control
													value={FormData.address_district}
													name='address_district'
													onChange={handleFieldChange}
													type='text'
												/>
											</Form.Group>
										</Col>
										<Col lg='3'>
											<Form.Group>
												<Form.Label>PDV</Form.Label>
												<Form.Select
													name='pdv_id'
													onChange={handleFieldChange}
													value={FormData.pdv_id}
													disabled={FormData.pdv_disabled}
												>
													<option value=''>Selecionar</option>
													{dataAuth?.user?.pdvs?.map((item: any, index: number) => (
														<option key={item.id} value={item.id}>
															{item?.name}
														</option>
													))}
												</Form.Select>
											</Form.Group>
										</Col>
									</Row>
								</div>
								{/* </Col> */}
							</Row>
						</Card.Body>
					</Card>
					{/* <Card className='mt-3'>
					<Card.Body>
						<div style={{ display: 'flex', justifyContent: 'flex-end',gap:"12px" }}>
							<CustomLinkLeft to='/clientes' />
							<Button type='submit'  color='primary'>
								<AiFillSave style={{ position: 'relative', top: '-1px' }} /> Salvar
							</Button>
						</div>
					</Card.Body>
				</Card> */}
				</Form>
			</Col>
			<HandleCustomerModal show={controlCustomerModal.show} isDark={isDark} onClose={() => {
				setControlCustomerModal({ show: false })

			}} dataPage={FormData} changeDataPage={setFormData} handleCep={handleCep} />
		</Row>
	)
}

export default FormCustomers
